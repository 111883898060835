/* eslint-disable @typescript-eslint/naming-convention */
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SSEService } from './sse.service';
import { AuthService } from './auth.service';
import { CRUD_BASE, LOGIC_BASE } from './endpoints';

export interface IdSSO {
  idsso: string;
}

export interface User extends IdSSO {
  id?: string;
  firstname?: string;
  surname?: string;
  ssn?: string;
  birthdate?: string;
  phone?: string;
  email?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends SSEService<User>{

  private static profilesUrlKey = 'profilesUrl';
  private utentiUrl = CRUD_BASE + '/user';

  constructor() {
    super('user');
  }

  getUtenti(): Observable<User[]> {
    return this.hget<User[]>(this.getProfilesUrl());
  }

  getUtente(id: string): Observable<User> {
    return this.hget<User>(`${this.getProfilesUrl()}/${id}`);
  }

  createUtente(body: IdSSO): Observable<string> {
    return this.hpost<string>(`${this.getProfilesUrl()}`, body).pipe(map((idUtente: string) => {
      this.hpost<string>(`${LOGIC_BASE}/auth/creaIdUtente/${idUtente}/${body.idsso}`, body);
      return idUtente;
    }));
  }

  updateUtente(id: string, body: object): Observable<string> {
    const rbody: User = {
      idsso: '',
      id: ''
    };
    Object.assign(rbody, body);
    delete rbody.updatedAt;
    delete rbody.deletedAt;
    delete rbody.createdAt;
    delete rbody.id;
    return this.hput<string>(`${this.getProfilesUrl()}/${id}`, rbody);
  }

  getUtenteByIdSSO(idsso: string): Observable<User[]> {
    return this.hget<User[]>(`${this.utentiUrl}/sso/${idsso}`);
  }

  setProfilesDomain(profilesDomain: string) {
    const profilesUrl = profilesDomain ? 'https://' + profilesDomain + this.utentiUrl : this.utentiUrl;
    sessionStorage.setItem(UserService.profilesUrlKey, profilesUrl);
  }

  getProfilesUrl(): string {
    return sessionStorage.getItem(UserService.profilesUrlKey);
  }

  deleteUtente(id: string): Observable<string> {
    return this.hdelete<string>(`${this.utentiUrl}/${id}`);
  }


}

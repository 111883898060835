import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RouteGuardService } from './guard/route-guard.service';
import { UserService } from './services/user.service';
import { LoginInfo } from '../main';
import { AuthService } from './services/auth.service';
import { bearerToken } from './app.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OpzioniService } from './services/options.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public guard: RouteGuardService,
    private router: Router,
    private uSvc: UserService,
    private authSvc: AuthService,
    private opzSvc: OpzioniService
  ) {}

  ngOnInit(): void {
    this.uSvc.setProfilesDomain((JSON.parse(sessionStorage.getItem('loginInfo')) as LoginInfo).profilesDomain);
    bearerToken.pipe(untilDestroyed(this)).subscribe(token => {
      this.authSvc.setBearerToken(token);
    });
    const temaDefault = 'atg';
    this.opzSvc.getWebappTheme().then(tema => {
      this.caricaTema('tema-grafico-custom', tema);
      // carico anche il tema di default, che sarà inserito *prima*
      // di quello custom, quindi meno prioritario.
      // In questo modo nel tema custom se voglio posso definire solo
      // alcune variabili e quelle mancanti saranno prese dal tema di
      // default
      this.caricaTema('tema-grafico-default', temaDefault);
      this.impostaIcona(tema);
    }).catch(err => {
      console.error(err);
      // in caso di problemi carico comunque il tema di default
      this.caricaTema('tema-grafico-default', temaDefault);
      this.impostaIcona(temaDefault);
    });
  }

  async onLogout() {
    this.uSvc.onLogout(); // cancella il sessionId da sessionStorage
    await this.guard.logout();
    this.authSvc.setBearerToken(undefined);
    this.authSvc.setIdUtenteLoggato(undefined);
    this.router.navigateByUrl('/');
  }

  caricaTema(linkId: string, tema: string) {
    this.aggiungiOModificaLink(linkId, tema, 'stylesheet', 'text/css', 'style.css');
  }

  aggiungiOModificaLink(linkId: string, tema: string, rel: string, type: string, hrefFile: string) {
    let link = document.getElementById(linkId) as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.id = linkId;
      link.rel = rel;
      link.type = type;

      const head = document.getElementsByTagName('head')[0];
      head.prepend(link);
    }

    link.href = `assets/temi/${tema}/${hrefFile}`;
  }

  impostaIcona(tema: string) {
    this.aggiungiOModificaLink('favicon-link', tema, 'icon', 'image/png', 'favicon.png');
  }


}

/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, inject } from '@angular/core';
import { EventSourceMessage, EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CrudService } from './crud.service';
import { NetworkDebugService } from './debug.service';
import { CRUD_BASE } from './endpoints';
import { PsiReplaySubject, SSE } from './sse';

class RetriableError extends Error { }
class FatalError extends Error { }

@Injectable({
  providedIn: 'root'
})
export abstract class SSEService<T> extends CrudService<T> {

  private dbgSvc = inject(NetworkDebugService);

  constructor(path: string) {
    super(path);
  }

  /**
   * Chiama il server per attivare un sessionId, da usare per tutte le successive chiamate SSE,
   * e per stabilire l'unica connessione SSE persistente per l'intera webapp.
   *
   * Se un id sessione è già presente nella sessione, allora usa quello.
   *
   * @param engage funzione che sarà chiamata non appena sarà disponibile la connessione SSE
   */
  initSSE(): Promise<void> {
    const sThis = this;
    return new Promise<void>((resolve, _reject) => {
      const existingSessionid = SSE.getSessionId();
      const sessionId = existingSessionid ?? uuidv4();

      const url = this.urlPrefix() + CRUD_BASE + '/sse/' + sessionId + "?keepalive=5000";
      SSE.abortController = new AbortController();
      sThis.onHidden();
      const { signal } = SSE.abortController;
      SSE.connection = fetchEventSource(url, {
        headers: super.addBearerToken().headers,
        async onopen(response: Response) {
          if (response.ok && response.headers.get('content-type').startsWith(EventStreamContentType)) {
            sThis.resubscribeAll(); // nel caso in cui ci fossero delle subscribe rimaste appese su una vecchioa connessione SSE
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
              // client-side errors are usually non-retriable:
              console.error(response);
              throw new FatalError();
          } else {
              throw new RetriableError();
          }
        },
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        onmessage(ev: EventSourceMessage) {
          try {
            if (ev.data) {
              const data = JSON.parse(ev.data);
              const eventId = data.event_id ?? undefined;
              if (eventId === 'sessionId') {
                SSE.setSessionId(data.event_payload);
                resolve();
              } else if (eventId === 'keepalive') {
                sThis.onKeepalive(parseInt(data.event_payload));
              } else {
                console.log('Ricevuti dati SSE per eventId=' + eventId);
                if (SSE.observables[eventId]) {
                  console.log('URL richiesta: ' + SSE.observables[eventId].url);
                  if (Array.isArray(data.event_payload)) {
                    const payload: T[] = data.event_payload as T[];
                    for (const p_el of payload) {
                      console.log('Payload ricevuto: ' + JSON.stringify(p_el));
                    }
                    SSE.observables[eventId].subject.next(payload);
                  } else {
                    console.error(data.event_payload);
                  }
                }
              }
            }
          } catch (err: any) {
            console.error(err);
          }
        },
        onclose() {
          // if the server closes the connection unexpectedly, retry:
          throw new RetriableError();
        },
        onerror(err) {
          if (err instanceof FatalError) {
            throw err; // rethrow to stop the operation
          } else {
            // connessione persa, non faccio nulla e la libreria Fetch Event Source automaticamente
            // tenterà di ripristinarla
          }
        },
        signal
      });
    });
  }

  closeSSE() {
    SSE.abortController?.abort();
    SSE.connection = undefined;
    SSE.perTuttiGliEndpoints((_url: string, eventsId: string, _subj: Observable<any>) => {
      this.unsubscribe(eventsId);
    });
    SSE.setSessionId(undefined);
  }

  onVisible() {
    const vch = () => {
      if (document.visibilityState == 'visible') {
        document.removeEventListener('visibilitychange', vch);
        this.resubscribeAll();
      }
    };
    document.addEventListener('visibilitychange', vch);
  }

  onHidden() {
    const vch = () => {
      if (document.visibilityState == 'hidden') {
        document.removeEventListener('visibilitychange', vch);
        this.closeSSE();
        this.onVisible();
        SSE.disableWatchdogs();
      }
    };
    document.addEventListener('visibilitychange', vch);
  }

  subscribe(url: string, eventsId: string, subj: PsiReplaySubject<T[]>) {
    const sessionId = SSE.getSessionId();
    if (typeof sessionId === 'undefined') {
      const recursion = () => {
        this.subscribe(url, eventsId, subj);
      };
      if (typeof SSE.connection === 'undefined') {
        this.initSSE().then(recursion);
      } else {
        setTimeout(recursion, 500);
      }
    } else {
      if (typeof SSE.connection === 'undefined') { // è stato fatto un reload manuale della pagina:
        // ne sono certo perché ho perso la connection, ma non ho perso il sessionId, essendo salvato in
        // sessionStorage. In questo caso posso ripartire da capo, tanto i componenti di Angular
        // rifaranno spontaneamente tutte le subscribe
        SSE.setSessionId(undefined);
        this.subscribe(url, eventsId, subj);
      } else {
        const subscribe = 'subscribe=' + sessionId + encodeURIComponent(',') + eventsId;
        const sseUrl = url + (url.indexOf('?') >= 0 ? '&' : '?') + subscribe;
        const uNs = {
          url: url,
          sseUrl: sseUrl,
          subject: subj
        };
        SSE.observables[eventsId] = uNs;
        const subscription = this.hget(uNs.sseUrl)
          .subscribe(_response => {
            subscription.unsubscribe();
          });
      }
    }
  }

  unsubscribe(eventsId: string) {
    // questa funzione sarà chiamata dopo l'ultima unsubscribe() su un determinato Observable
    // ed avvisa il server che le notifiche SSE relative a questa subscription non ci servono più
    const delurl = CRUD_BASE + '/sse/' + SSE.getSessionId() + '/' + eventsId;
    const subs = this.hdelete(delurl).subscribe(d => subs.unsubscribe());
  }

  resubscribeAll() {
    SSE.perTuttiGliEndpoints(this.subscribe.bind(this));
    SSE.debugCheckCountdown = 3; // attendo 3 keepalive e poi controllo che tutte le subscription siano state ripristinate correttamente
  }

  getSSE(url: string): Observable<T[]> {
    const eventsId = uuidv4();
    const subj = new PsiReplaySubject<T[]>(1, eventsId, () => {
      this.unsubscribe(eventsId);
    });
    this.subscribe(url, eventsId, subj);
    return subj;
  }

  onLogout() {
    this.closeSSE();
  }

  async onKeepalive(baseKeepaliveTimeout: number) {
    // il server mi ha mandato il keepalive, quindi smetto di attenderlo
    // e faccio ripartire il timeout per attendere il prossimo (entro il
    // timeout ricevuto qui come parametro, che mi è stato inviato dal server)
    SSE.resettaWatchdogs({
      "RETEINSTABILE": {
        delayFactor: 2,
        // se arrivo qui significa che il server è in ritardo sul keepalive di
        // almeno due volte l'intervallo che aveva promesso, quindi emetto un warning
        // che potrebbe determinare un popup sui monitor/totem
        fn: this.dbgSvc.signalUnstableNetworkConnection.bind(this.dbgSvc)
      },
      "CONNESSIONEKO": {
        delayFactor: 4,
        // se arrivo qui significa che il server non mi ha mandato il keepalive entro
        // 4 volte l'intervallo che aveva promesso, quindi ripristino la connessione SSE
        fn: () => {
          this.dbgSvc.signalKoConnection();
          this.closeSSE();
          this.resubscribeAll(); // imposta il countdown di keepalive a 3, arrivati i quali faremo un check
        }
      }
    }, baseKeepaliveTimeout);
    // essendo arrivato il keepalive, elimino tutte le eventuali segnalazioni di
    // problemi precedenti: questo farà chiudere eventuali popup sui monitor/totem
    this.dbgSvc.signalStableNetworkConnection();
    this.dbgSvc.signalOkConnection();
  }

  debugService(): NetworkDebugService {
    return this.dbgSvc;
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LOGIC_BASE } from './app/services/endpoints';

export interface LoginInfo {
  baseUrl: string;
  url: string;
  realm: string;
  client: string;
  profilesDomain: string;
};

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded',  () => {
  const bootme = async () => {
    sessionStorage.setItem('loginInfo', '');
    const logicAuthPath = LOGIC_BASE + '/auth';
    const loginInfoPath = '/loginInfo/index.html'; // index.html è l'URL relativo a cui fare il redirect dopo il login.
                                                  // In altre parole non si sta caricando index.html dal server, ma
                                                  // si sta passando la stringa "index.html" come URL a cui il CLIENT
                                                  // dovrà essere rediretto dopo aver fatto il login.
    const infoEndpoint = logicAuthPath + loginInfoPath;

    const parseLoginInfo = async (resp: any) => {
      const result = await resp.text();
      sessionStorage.setItem('loginInfo', result);
      platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
    };

    try {
      const response = await fetch(infoEndpoint);
      if (response.status === 200) {
        document.getElementById('connecting').style.display = 'none';
        await parseLoginInfo(response);
      } else {
        document.getElementById('optifstbody').style.display = 'block';
        setTimeout(bootme, 3000);
      }
    } catch (err: any) {
      console.error(err);
      document.getElementById('optifstbody').style.display = 'block';
      setTimeout(bootme, 3000);
  }  };
  bootme();

});

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map } from 'rxjs';
import { Entity } from './crud.service';
import { SSEService } from './sse.service';

export interface Opzione extends Entity {
  tabella: string;
  riga: string;
  chiave: string;
  valore: string;
  descrizione?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OpzioniService extends SSEService<Opzione>{

  constructor() {
    super('options');
  }


  getCached(endpoint: string): Promise<string> {
    /* tiene in cache il tema per non fare una chiamata ogni volta, tanto fondamentalemnte non cambia mai */
    const cachedValue = window.sessionStorage.getItem(endpoint);
    if (cachedValue === null) {
      const opzObs = this.hget<string>(endpoint)
      .pipe(map(val => {
        window.sessionStorage.setItem(endpoint, val);
        return val;
      } ));
      return firstValueFrom(opzObs);
    } else {
      return Promise.resolve(cachedValue);
    }
  }

  getWebappTheme(): Promise<string> {
    /* tiene in cache il tema per non fare una chiamata ogni volta, tanto fondamentalemnte non cambia mai */
    return this.getCached(`${this.logicBase}/${this.path}/webapptheme`);
  }
}

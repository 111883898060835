/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './baseservice';
import { LOGIC_BASE } from './endpoints';
import { SSE, SubscribedEndpoint } from './sse';

export abstract class NetworkBug {
  constructor(private active: boolean) {}

  abstract getMessage(): string;

  public isActive() {
    return this.active;
  }
}
class NetworkUnreliableBug extends NetworkBug {
  getMessage(): string {
    return 'Slow or unstable network connection';
  }
}

class NetworkDisconnectedBug extends NetworkBug {
  getMessage(): string {
    return 'Disconnected: waiting for reconnect';
  }
}

export class SubscriptionBug extends NetworkBug {

  constructor(public mancantiLatoServer: SubscribedEndpoint[],
              public mancantiLatoClient: SubscribedEndpoint[]) {
      super(mancantiLatoServer.length + mancantiLatoClient.length > 0);
  }

  getMessage(): string {
    return 'Unexpected data discrepancy';
  }
}

@Injectable({
  providedIn: 'root',
})
export class NetworkDebugService extends BaseService<SubscribedEndpoint> {
  private unstableNetworkConnection = new Subject<NetworkUnreliableBug>();
  private connectionProblems = new Subject<NetworkDisconnectedBug>();
  private notMatchedSubscriptions = new Subject<SubscriptionBug>();

  getUnstableNetworkConnection(): Observable<NetworkBug> {
    return this.unstableNetworkConnection;
  }

  signalUnstableNetworkConnection() {
    this.unstableNetworkConnection.next(new NetworkUnreliableBug(true));
  }

  signalStableNetworkConnection() {
    this.unstableNetworkConnection.next(new NetworkUnreliableBug(false));
  }

  getConnectionProblems(): Observable<NetworkBug> {
    return this.connectionProblems;
  }

  signalOkConnection() {
    this.connectionProblems.next(new NetworkDisconnectedBug(false));
  }

  signalKoConnection() {
    this.connectionProblems.next(new NetworkDisconnectedBug(true));
  }

  getNotMatchedSubscriptions(): Observable<SubscriptionBug> {
    return this.notMatchedSubscriptions;
  }

  signalNotMatchedSubscriptions(bug: SubscriptionBug) {
    this.notMatchedSubscriptions.next(bug);
  }
}

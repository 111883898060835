import { Observer, ReplaySubject, Subscription } from 'rxjs';
import { StorageService } from './storage.service';

export interface PsiUnsubscribeNotifiable {
  notifyUnsubscribed(): void;
}

export class PsiReplaySubject<T> extends ReplaySubject<T> implements PsiUnsubscribeNotifiable {

  private subscriptionsCount = 0;
  private seenFirstSubscribe = false;

  constructor(replays: number,
              private eventsId: string,
              private onClose: () => void) {
    super(replays);

    // la funzione onClose serve a comunicare al server che un determinato eventsId non ci
    // serve più e le relative risorse possono quindi essere liberate.
    // Viene chiamata quando tutte le subscribe a questo Observable sono state chiuse con
    // relativa unsubscribe, quindi il tutto accade automaticamente quando il component
    // che ha fatto la subscribe nella ngOnInit, fa la relativa unsubscribe nella ngOnDestroy.
  }

  override subscribe(observerOrNext?: Partial<Observer<T>> | ((value: T) => void)): Subscription {
    this.subscriptionsCount++;
    this.seenFirstSubscribe = true;
    const subs = super.subscribe(observerOrNext);
    new PsiSubscription(this, subs);
    return subs;
  }

  notifyUnsubscribed(): void {
    this.subscriptionsCount--;
    if (this.subscriptionsCount === 0 && this.seenFirstSubscribe) {
      this.complete();
      delete SSE.observables[this.eventsId];
      this.onClose();
    }
  }
}

export class PsiSubscription {
  private originalUnsubscribe;

  constructor(private wrapper: PsiUnsubscribeNotifiable,
              wrapped: Subscription) {
    this.originalUnsubscribe = wrapped.unsubscribe.bind(wrapped);
    wrapped.unsubscribe = this.unsubscribe.bind(this);
  }

  unsubscribe(): void {
    this.originalUnsubscribe();
    this.wrapper.notifyUnsubscribed();
  }
}

export interface SubscribedEndpoint {
  subscriptionId: string,
  endpoint: string
}

export interface UrlAndSubject<T> {
  url: string;
  sseUrl: string;
  subject: PsiReplaySubject<T>;
}

export interface ObservablesMap {
  [eventsId: string]: UrlAndSubject<any>;
}
export interface WatchdogCallback {
  delayFactor: number;
  fn: () => any;
}

export interface Watchdogs {
  [wdid: string]: WatchdogCallback;
}

interface WatchdogTimers {
  [wdid: string]: any; // risultato di setTimer
}

export class SSE {

  private static sessionIdKey = "sseSessionId_" + Math.random();

  public static connection: Promise<void> = undefined;
  public static abortController: AbortController = undefined;
  public static observables: ObservablesMap = {};
  public static debugCheckCountdown = 0;
  private static watchdogTimers: WatchdogTimers = {};

  public static setSessionId(ci: string) {
    StorageService.sSet(SSE.sessionIdKey, ci);
  }

  public static getSessionId(): string | undefined {
    return StorageService.sGet(SSE.sessionIdKey);
  }

  public static situazione(): SubscribedEndpoint[] {
    const result: SubscribedEndpoint[] = [];
    const sessionId = StorageService.sGet(SSE.sessionIdKey);
    if (typeof SSE.connection !== 'undefined') {
      if (typeof sessionId !== 'undefined') {
        for (const evid in SSE.observables) {
          if (Object.hasOwn(SSE.observables, evid)) {
            result.push({
              subscriptionId: evid,
              endpoint: SSE.observables[evid].sseUrl
            });
          }
        }
      }
    }
    return result;
  }

  public static perTuttiGliEndpoints(callback: (url: string, eventsId: string, subj: PsiReplaySubject<any>) => any) {
    if (SSE.observables)
      for (const evid in SSE.observables) {
        if (Object.hasOwn(SSE.observables, evid)) {
          callback(SSE.observables[evid].url, evid, SSE.observables[evid].subject);
        }
      }
  }

  private static resettaWatchdogFn(timeout: number, fn: () => any, watchdogIndex: string) {
    if (Object.hasOwn(SSE.watchdogTimers, watchdogIndex)) {
      clearTimeout(SSE.watchdogTimers[watchdogIndex]);
    }

    SSE.watchdogTimers[watchdogIndex] = setTimeout(fn, timeout);
  }

  public static disableWatchdogs() {
    // disabilito tutti i watchdog
    const nullWatchdogs: Watchdogs = {};
    const existingWDKeys = Object.keys(SSE.watchdogTimers);
    for (const k in existingWDKeys) {
      nullWatchdogs[k] = {
        delayFactor: 10,
        fn: () => {}
      };
    }
    SSE.resettaWatchdogs(nullWatchdogs, 86400 * 1000);
  }

  public static resettaWatchdogs(wdcbs: Watchdogs, baseKeepaliveTimeout: number) {
    const keys = Object.keys(wdcbs);
    for (const k of keys) {
      this.resettaWatchdogFn(wdcbs[k].delayFactor * baseKeepaliveTimeout, wdcbs[k].fn, k);
    }
  }
}

